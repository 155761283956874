/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/lesson_unit_book.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./lesson_unit_book_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonUnitBookServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonUnitBookServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetLessonUnitBookRequest,
 *   !proto.dash.v1.GetLessonUnitBookResponse>}
 */
const methodDescriptor_LessonUnitBookService_GetLessonUnitBook = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitBookService/GetLessonUnitBook',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetLessonUnitBookRequest,
  proto.dash.v1.GetLessonUnitBookResponse,
  /**
   * @param {!proto.dash.v1.GetLessonUnitBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetLessonUnitBookResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetLessonUnitBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetLessonUnitBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetLessonUnitBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitBookServiceClient.prototype.getLessonUnitBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitBookService/GetLessonUnitBook',
      request,
      metadata || {},
      methodDescriptor_LessonUnitBookService_GetLessonUnitBook,
      callback);
};


/**
 * @param {!proto.dash.v1.GetLessonUnitBookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetLessonUnitBookResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitBookServicePromiseClient.prototype.getLessonUnitBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitBookService/GetLessonUnitBook',
      request,
      metadata || {},
      methodDescriptor_LessonUnitBookService_GetLessonUnitBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutLessonUnitBookRequest,
 *   !proto.dash.v1.PutLessonUnitBookResponse>}
 */
const methodDescriptor_LessonUnitBookService_PutLessonUnitBook = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitBookService/PutLessonUnitBook',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutLessonUnitBookRequest,
  proto.dash.v1.PutLessonUnitBookResponse,
  /**
   * @param {!proto.dash.v1.PutLessonUnitBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutLessonUnitBookResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutLessonUnitBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutLessonUnitBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutLessonUnitBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitBookServiceClient.prototype.putLessonUnitBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitBookService/PutLessonUnitBook',
      request,
      metadata || {},
      methodDescriptor_LessonUnitBookService_PutLessonUnitBook,
      callback);
};


/**
 * @param {!proto.dash.v1.PutLessonUnitBookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutLessonUnitBookResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitBookServicePromiseClient.prototype.putLessonUnitBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitBookService/PutLessonUnitBook',
      request,
      metadata || {},
      methodDescriptor_LessonUnitBookService_PutLessonUnitBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListLessonUnitBooksRequest,
 *   !proto.dash.v1.ListLessonUnitBooksResponse>}
 */
const methodDescriptor_LessonUnitBookService_ListLessonUnitBooks = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitBookService/ListLessonUnitBooks',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListLessonUnitBooksRequest,
  proto.dash.v1.ListLessonUnitBooksResponse,
  /**
   * @param {!proto.dash.v1.ListLessonUnitBooksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListLessonUnitBooksResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListLessonUnitBooksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListLessonUnitBooksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListLessonUnitBooksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitBookServiceClient.prototype.listLessonUnitBooks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitBookService/ListLessonUnitBooks',
      request,
      metadata || {},
      methodDescriptor_LessonUnitBookService_ListLessonUnitBooks,
      callback);
};


/**
 * @param {!proto.dash.v1.ListLessonUnitBooksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListLessonUnitBooksResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitBookServicePromiseClient.prototype.listLessonUnitBooks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitBookService/ListLessonUnitBooks',
      request,
      metadata || {},
      methodDescriptor_LessonUnitBookService_ListLessonUnitBooks);
};


module.exports = proto.dash.v1;

