import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { status } from "../../api/types";
import { LessonUnit, Area, Lesson } from "../../grpc/dash/v1/models_pb";
import { lessonUnitServiceGetLessonUnit, lessonUnitServicePutLessonUnit } from "../../api/lesson_unit";
import { lessonServiceGetLesson } from '../../api/lesson';
import FileToBase64 from "../../helpers/fileToBase64";

function LessonUnitEdit() {
    let { lessonId } = useParams();
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new LessonUnit().setArea(new Area()).setLesson(new Lesson().setId(lessonId)).toObject());
    const [file, setFile] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [lesson, setLesson] = useState(new Lesson().toObject());
    const loadLesson = async () => {
        const response = await lessonServiceGetLesson(lessonId);
        const object = response.toObject();
        setLesson(object.lesson);
    }

    const loadScreen = async () => {
        if (lessonId === undefined)
            navigate(`/lessons`);
        try {

            await loadLesson(lessonId);
            const breadcrumbItems = [];
            if (id === undefined) {
                breadcrumbItems.push({ name: "YENİ", url: "" });
            } else {
                const result = await lessonUnitServiceGetLessonUnit(id);
                setData(result.toObject().lessonUnit)
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {
        switch (key) {
            case "unitNumber":
            case "status":
                data[key] = parseInt(value)
                break;
            default:
                data[key] = value;
                break;
        }

        setData({ ...data })
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            let result = await lessonUnitServicePutLessonUnit(data, file);
            console.log(result)
            let object = result.toObject().lessonUnit
            console.log(object);
            navigate(`/lessons/${object.lesson.id}/units`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }




    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />

        return <form className="card-body row g-3">
            <div className="col-md-12 py-1">
                <label htmlFor="form-status" className="form-label">DURUM</label>
                <select id="form-status" className="form-select" defaultValue={data.status} onChange={e => setCustomData("status", e.target.value)}>
                    {
                        Object.values(status()).map((value, index) => (
                            <option key={"status" + index} value={value}>{status(value)}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-md-12 py-1">
                <label htmlFor="form-title" className="form-label">BAŞLIK</label>
                <input type="text" className="form-control" id="form-title" value={data.title} onChange={e => setCustomData("title", e.target.value)} />
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-unit-number" className="form-label">ÜNİTE NUMARASI</label>
                <input type="number" className="form-control" id="form-unit-number" value={data.unitNumber} onChange={e => setCustomData("unitNumber", e.target.value)} />
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default LessonUnitEdit;