import { Status } from '../grpc/types/v1/status_pb';
import { AcademicYear } from '../grpc/types/v1/academic_year_pb';
import { ExamStatus } from '../grpc/types/v1/exam_status_pb';
import { QuestionOption } from '../grpc/types/v1/question_option_pb';
import { PostStatus } from '../grpc/types/v1/post_status_pb';
import { PostType } from '../grpc/types/v1/post_type_pb';
import { CalendarEventType } from '../grpc/types/v1/calendar_event_type_pb';
import { Entity } from '../grpc/types/v1/entity_pb';
import { LessonContentType } from '../grpc/types/v1/lesson_content_type_pb';
import { LessonUnitContentType } from '../grpc/types/v1/lesson_unit_content_type_pb';
import { Empty } from '@bufbuild/protobuf';

export const status = (value) => {
    if (value === undefined)
        return Status;
    switch (value) {
        case Status.STATUS_UNSPECIFIED: return "Belirtilmemiş";
        case Status.STATUS_ACTIVE: return "Aktif";
        case Status.STATUS_DELETED: return "Çöp";
        default: return "Bilinmiyor";
    }
}

export const postStatus = (value) => {
    if (value === undefined)
        return PostStatus;
    switch (value) {
        case PostStatus.POST_STATUS_UNSPECIFIED: return "Belirtilmemiş"
        case PostStatus.POST_STATUS_PUBLISH: return "Yayında"
        case PostStatus.POST_STATUS_PENDING: return "Onay Bekliyor"
        case PostStatus.POST_STATUS_DRAFT: return "Taslak"
        case PostStatus.POST_STATUS_TRASH: return "Çöp"
        default: return "Bilinmiyor";
    }
}

export const postType = (value) => {
    if (value === undefined)
        return PostType;
    switch (value) {
        case PostType.POST_TYPE_UNSPECIFIED: return "Belirtilmemiş";
        case PostType.POST_TYPE_NEWS: return "Haber";
        case PostType.POST_TYPE_ANNOUNCEMENT: return "Duyuru";
        case PostType.POST_TYPE_PAGE: return "Sayfa";
        case PostType.POST_TYPE_FAQ: return "SSS";
        default: return "Bilinmiyor";
    }
}

export const examStatus = (value) => {
    if (value === undefined)
        return ExamStatus;
    switch (value) {
        case ExamStatus.EXAM_STATUS_UNSPECIFIED: return "Belirtilmemiş";
        case ExamStatus.EXAM_STATUS_PUBLISH: return "Yayında";
        case ExamStatus.EXAM_STATUS_PENDING: return "Onay Bekliyor";
        case ExamStatus.EXAM_STATUS_DRAFT: return "Taslak";
        case ExamStatus.EXAM_STATUS_TRASH: return "Çöp";
        case ExamStatus.EXAM_STATUS_NEW: return "Yeni";
        case ExamStatus.EXAM_STATUS_FAILED: return "Hatalı";
        case ExamStatus.EXAM_STATUS_TRIM_PENDING: return "Onay Bekliyor (Temizlendi)";
        case ExamStatus.EXAM_STATUS_TRIM_IN_PROGRESS: return "Kırpılıyor";
        default: return "Bilinmiyor"
    }
}

export const academicYear = (val) => {
    if (val === undefined)
        return AcademicYear;
    switch (val) {
        case AcademicYear.ACADEMIC_YEAR_UNSPECIFIED: return "Belirtilmemiş"
        case AcademicYear.ACADEMIC_YEAR_2010: return "2009-2010"
        case AcademicYear.ACADEMIC_YEAR_2011: return "2010-2011"
        case AcademicYear.ACADEMIC_YEAR_2012: return "2011-2012"
        case AcademicYear.ACADEMIC_YEAR_2013: return "2012-2013"
        case AcademicYear.ACADEMIC_YEAR_2014: return "2013-2014"
        case AcademicYear.ACADEMIC_YEAR_2015: return "2014-2015"
        case AcademicYear.ACADEMIC_YEAR_2016: return "2015-2016"
        case AcademicYear.ACADEMIC_YEAR_2017: return "2016-2017"
        case AcademicYear.ACADEMIC_YEAR_2018: return "2017-2018"
        case AcademicYear.ACADEMIC_YEAR_2019: return "2018-2019"
        case AcademicYear.ACADEMIC_YEAR_2020: return "2019-2020"
        case AcademicYear.ACADEMIC_YEAR_2021: return "2020-2021"
        case AcademicYear.ACADEMIC_YEAR_2022: return "2021-2022"
        case AcademicYear.ACADEMIC_YEAR_2023: return "2022-2023"
        case AcademicYear.ACADEMIC_YEAR_2024: return "2023-2024"
        case AcademicYear.ACADEMIC_YEAR_2025: return "2024-2025"
        default: return "Bilinmiyor"
    }
}

export const questionOption = (value) => {
    if (value === undefined)
        return QuestionOption;
    switch (value) {
        case QuestionOption.QUESTION_OPTION_CANCELED: return "İ";
        case QuestionOption.QUESTION_OPTION_A: return "A";
        case QuestionOption.QUESTION_OPTION_B: return "B";
        case QuestionOption.QUESTION_OPTION_C: return "C";
        case QuestionOption.QUESTION_OPTION_D: return "D";
        case QuestionOption.QUESTION_OPTION_E: return "E";
        default: return "-";
    }
}
export const questionOptionKey = (value) => {
    switch (value) {
        case "İ": return QuestionOption.QUESTION_OPTION_CANCELED;
        case "A": return QuestionOption.QUESTION_OPTION_A;
        case "B": return QuestionOption.QUESTION_OPTION_B;
        case "C": return QuestionOption.QUESTION_OPTION_C;
        case "D": return QuestionOption.QUESTION_OPTION_D;
        case "E": return QuestionOption.QUESTION_OPTION_E;
        default: return QuestionOption.QUESTION_OPTION_UNSPECIFIED;
    }
}

export const calendarEventType = (value) => {
    if (value === undefined)
        return CalendarEventType;
    switch (value) {
        case CalendarEventType.CALENDAR_EVENT_TYPE_UNSPECIFIED: return "Belirtilmemiş";
        case CalendarEventType.CALENDAR_EVENT_TYPE_EXAM: return "Sınav";
        default: return "Bilinmiyor";
    }
}

export const entity = (value) => {
    if (value === undefined)
        return Entity;
    switch (value) {
        case Entity.ENTITY_UNSPECIFIED: return "Belirtilmemiş";
        case Entity.ENTITY_POST: return "Yazı";
        default: return "Bilinmiyor";
    }
}


export const lessonContentType = (value) => {
    if (value === undefined)
        return LessonContentType;
    switch (value) {
        case LessonContentType.LESSON_CONTENT_TYPE_UNSPECIFIED: return "Belirtilmemiş";
        case LessonContentType.LESSON_CONTENT_TYPE_BOOK: return "Kitap";
        case LessonContentType.LESSON_CONTENT_TYPE_SUMMARY: return "Özet";
        default: return "Bilinmiyor";
    }
}

export const lessonUnitContentType = (value) => {
    if (value === undefined)
        return LessonUnitContentType;
    switch (value) {
        case LessonUnitContentType.LESSON_UNIT_CONTENT_TYPE_BOOK: return "Kitap";
        case LessonUnitContentType.LESSON_UNIT_CONTENT_TYPE_SUMMARY: return "Özet";
        default: return "Bilinmiyor";
    }
}