import { lessonUnitService } from './services';
import { GetLessonUnitRequest, PutLessonUnitRequest, ListLessonUnitsRequest } from '../grpc/dash/v1/lesson_unit_pb';
import { LessonUnit, Area, Lesson } from '../grpc/dash/v1/models_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export const lessonUnitServiceGetLessonUnit = (id) => lessonUnitService.getLessonUnit(new GetLessonUnitRequest().setId(id));

export const lessonUnitServiceListLessonUnits = ({ areaId, lessonId, status }) => lessonUnitService.listLessonUnits(new ListLessonUnitsRequest()
    .setAreaId(areaId)
    .setLessonId(lessonId)
    .setStatus(status));

export const lessonUnitServicePutLessonUnit = (unit) => lessonUnitService.putLessonUnit(
    new PutLessonUnitRequest()
        .setLessonUnit(
            new LessonUnit()
                .setId(unit.id)
                .setLesson(new Lesson().setId(unit.lesson.id))
                .setStatus(unit.status)
                .setUnitNumber(unit.unitNumber)
                .setTitle(unit.title)
        ));