/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/lesson_unit_content.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./lesson_unit_content_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonUnitContentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonUnitContentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetLessonUnitContentRequest,
 *   !proto.dash.v1.GetLessonUnitContentResponse>}
 */
const methodDescriptor_LessonUnitContentService_GetLessonUnitContent = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitContentService/GetLessonUnitContent',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetLessonUnitContentRequest,
  proto.dash.v1.GetLessonUnitContentResponse,
  /**
   * @param {!proto.dash.v1.GetLessonUnitContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetLessonUnitContentResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetLessonUnitContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetLessonUnitContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetLessonUnitContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitContentServiceClient.prototype.getLessonUnitContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitContentService/GetLessonUnitContent',
      request,
      metadata || {},
      methodDescriptor_LessonUnitContentService_GetLessonUnitContent,
      callback);
};


/**
 * @param {!proto.dash.v1.GetLessonUnitContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetLessonUnitContentResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitContentServicePromiseClient.prototype.getLessonUnitContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitContentService/GetLessonUnitContent',
      request,
      metadata || {},
      methodDescriptor_LessonUnitContentService_GetLessonUnitContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutLessonUnitContentRequest,
 *   !proto.dash.v1.PutLessonUnitContentResponse>}
 */
const methodDescriptor_LessonUnitContentService_PutLessonUnitContent = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitContentService/PutLessonUnitContent',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutLessonUnitContentRequest,
  proto.dash.v1.PutLessonUnitContentResponse,
  /**
   * @param {!proto.dash.v1.PutLessonUnitContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutLessonUnitContentResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutLessonUnitContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutLessonUnitContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutLessonUnitContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitContentServiceClient.prototype.putLessonUnitContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitContentService/PutLessonUnitContent',
      request,
      metadata || {},
      methodDescriptor_LessonUnitContentService_PutLessonUnitContent,
      callback);
};


/**
 * @param {!proto.dash.v1.PutLessonUnitContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutLessonUnitContentResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitContentServicePromiseClient.prototype.putLessonUnitContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitContentService/PutLessonUnitContent',
      request,
      metadata || {},
      methodDescriptor_LessonUnitContentService_PutLessonUnitContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListLessonUnitContentsRequest,
 *   !proto.dash.v1.ListLessonUnitContentsResponse>}
 */
const methodDescriptor_LessonUnitContentService_ListLessonUnitContents = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitContentService/ListLessonUnitContents',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListLessonUnitContentsRequest,
  proto.dash.v1.ListLessonUnitContentsResponse,
  /**
   * @param {!proto.dash.v1.ListLessonUnitContentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListLessonUnitContentsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListLessonUnitContentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListLessonUnitContentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListLessonUnitContentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitContentServiceClient.prototype.listLessonUnitContents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitContentService/ListLessonUnitContents',
      request,
      metadata || {},
      methodDescriptor_LessonUnitContentService_ListLessonUnitContents,
      callback);
};


/**
 * @param {!proto.dash.v1.ListLessonUnitContentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListLessonUnitContentsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitContentServicePromiseClient.prototype.listLessonUnitContents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitContentService/ListLessonUnitContents',
      request,
      metadata || {},
      methodDescriptor_LessonUnitContentService_ListLessonUnitContents);
};


module.exports = proto.dash.v1;

