import { AreaServicePromiseClient } from '../grpc/dash/v1/area_grpc_web_pb';
import { CalendarEventServicePromiseClient } from '../grpc/dash/v1/calendar_event_grpc_web_pb';
import { PastExamBookletServicePromiseClient } from '../grpc/dash/v1/past_exam_booklet_grpc_web_pb';
import { ExamGroupServicePromiseClient } from '../grpc/dash/v1/exam_group_grpc_web_pb';
import { ExamTypeServicePromiseClient } from '../grpc/dash/v1/exam_type_grpc_web_pb';
import { PastExamServicePromiseClient } from '../grpc/dash/v1/past_exam_grpc_web_pb';
import { PostServicePromiseClient } from '../grpc/dash/v1/post_grpc_web_pb';
import { FileServicePromiseClient } from '../grpc/dash/v1/file_grpc_web_pb';
import { LessonBookServicePromiseClient } from '../grpc/dash/v1/lesson_book_grpc_web_pb';
import { LessonServicePromiseClient } from '../grpc/dash/v1/lesson_grpc_web_pb';
import { LessonUnitBookServicePromiseClient } from '../grpc/dash/v1/lesson_unit_book_grpc_web_pb';
import { LessonUnitContentServicePromiseClient } from '../grpc/dash/v1/lesson_unit_content_grpc_web_pb';
import { LessonUnitServicePromiseClient } from '../grpc/dash/v1/lesson_unit_grpc_web_pb';
import { FAQServicePromiseClient } from '../grpc/dash/v1/faq_grpc_web_pb';
import { AuthServicePromiseClient } from '../grpc/auth/v1/auth_grpc_web_pb';
import { UserServicePromiseClient } from '../grpc/auth/v1/user_grpc_web_pb';

import { AuthInterceptor } from './interceptors/unary';
import { AuthStreamInterceptor } from './interceptors/stream';

import { Mutex } from 'async-mutex';

export const mutex = new Mutex();
const commonHost = process.env.REACT_APP_API_URL;
const commonInterceptors = {
    unaryInterceptors: [new AuthInterceptor()],
    streamInterceptors: [new AuthStreamInterceptor()],
};



export const authClient = new AuthServicePromiseClient(commonHost, null, null);
export const userClient = new UserServicePromiseClient(commonHost, null, commonInterceptors);


export const authService = new AuthServicePromiseClient(commonHost, null, null);
export const userService = new UserServicePromiseClient(commonHost, null, commonInterceptors);



export const areaService = new AreaServicePromiseClient(commonHost, null, commonInterceptors);
export const calendarEventService = new CalendarEventServicePromiseClient(commonHost, null, commonInterceptors);
export const pastExamBookletService = new PastExamBookletServicePromiseClient(commonHost, null, commonInterceptors);
export const examGroupService = new ExamGroupServicePromiseClient(commonHost, null, commonInterceptors);
export const examTypeService = new ExamTypeServicePromiseClient(commonHost, null, commonInterceptors);
export const pastExamService = new PastExamServicePromiseClient(commonHost, null, commonInterceptors);
export const postService = new PostServicePromiseClient(commonHost, null, commonInterceptors);
export const fileService = new FileServicePromiseClient(commonHost, null, commonInterceptors);
export const lessonBookService = new LessonBookServicePromiseClient(commonHost, null, commonInterceptors);
export const lessonService = new LessonServicePromiseClient(commonHost, null, commonInterceptors);
export const lessonUnitBookService = new LessonUnitBookServicePromiseClient(commonHost, null, commonInterceptors);
export const lessonUnitContentService = new LessonUnitContentServicePromiseClient(commonHost, null, commonInterceptors);
export const lessonUnitService = new LessonUnitServicePromiseClient(commonHost, null, commonInterceptors);
export const faqService = new FAQServicePromiseClient(commonHost, null, commonInterceptors);