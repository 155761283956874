/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/lesson_unit.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./lesson_unit_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonUnitServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonUnitServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetLessonUnitRequest,
 *   !proto.dash.v1.GetLessonUnitResponse>}
 */
const methodDescriptor_LessonUnitService_GetLessonUnit = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitService/GetLessonUnit',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetLessonUnitRequest,
  proto.dash.v1.GetLessonUnitResponse,
  /**
   * @param {!proto.dash.v1.GetLessonUnitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetLessonUnitResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetLessonUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetLessonUnitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetLessonUnitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitServiceClient.prototype.getLessonUnit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitService/GetLessonUnit',
      request,
      metadata || {},
      methodDescriptor_LessonUnitService_GetLessonUnit,
      callback);
};


/**
 * @param {!proto.dash.v1.GetLessonUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetLessonUnitResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitServicePromiseClient.prototype.getLessonUnit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitService/GetLessonUnit',
      request,
      metadata || {},
      methodDescriptor_LessonUnitService_GetLessonUnit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutLessonUnitRequest,
 *   !proto.dash.v1.PutLessonUnitResponse>}
 */
const methodDescriptor_LessonUnitService_PutLessonUnit = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitService/PutLessonUnit',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutLessonUnitRequest,
  proto.dash.v1.PutLessonUnitResponse,
  /**
   * @param {!proto.dash.v1.PutLessonUnitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutLessonUnitResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutLessonUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutLessonUnitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutLessonUnitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitServiceClient.prototype.putLessonUnit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitService/PutLessonUnit',
      request,
      metadata || {},
      methodDescriptor_LessonUnitService_PutLessonUnit,
      callback);
};


/**
 * @param {!proto.dash.v1.PutLessonUnitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutLessonUnitResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitServicePromiseClient.prototype.putLessonUnit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitService/PutLessonUnit',
      request,
      metadata || {},
      methodDescriptor_LessonUnitService_PutLessonUnit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListLessonUnitsRequest,
 *   !proto.dash.v1.ListLessonUnitsResponse>}
 */
const methodDescriptor_LessonUnitService_ListLessonUnits = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonUnitService/ListLessonUnits',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListLessonUnitsRequest,
  proto.dash.v1.ListLessonUnitsResponse,
  /**
   * @param {!proto.dash.v1.ListLessonUnitsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListLessonUnitsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListLessonUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListLessonUnitsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListLessonUnitsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonUnitServiceClient.prototype.listLessonUnits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonUnitService/ListLessonUnits',
      request,
      metadata || {},
      methodDescriptor_LessonUnitService_ListLessonUnits,
      callback);
};


/**
 * @param {!proto.dash.v1.ListLessonUnitsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListLessonUnitsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonUnitServicePromiseClient.prototype.listLessonUnits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonUnitService/ListLessonUnits',
      request,
      metadata || {},
      methodDescriptor_LessonUnitService_ListLessonUnits);
};


module.exports = proto.dash.v1;

