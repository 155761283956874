import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen.js';
import ErrorContent from '../../components/ErrorContent.js';
import NoContent from '../../components/NoContent.js';
import Breadcrumbs from '../../components/Breadcrumbs.js';
import { lessonUnitServiceListLessonUnits } from '../../api/lesson_unit';
import { lessonServiceGetLesson } from '../../api/lesson';
import { status } from '../../api/types';

function LessonUnits() {
    const navigate = useNavigate();
    let { lessonId } = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lesson, setLesson] = useState(null);
    const [data, setData] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const loadScreen = async () => {
        if (lessonId === undefined)
            navigate(`/lessons`);

        try {
            await loadLesson();
            await loadLessonUnits();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadLesson = async () => {
        const response = await lessonServiceGetLesson(lessonId);
        const object = response.toObject();
        setLesson(object.lesson);
    }
    const loadLessonUnits = async () => {
        const response = await lessonUnitServiceListLessonUnits({ lessonId: lessonId });
        const object = response.toObject();
        setData(object.lessonUnitsList);
    }

    useEffect(() => {
        loadScreen();
    }, []);

    useEffect(() => {
        document.title = `${lesson?.title ?? '-'} / Üniteler`
        console.log(lesson)
        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Dersler', url: "/lessons" },
            { name: lesson?.area.title ?? "-", url: `/lessons?areaId=${lesson?.area.id ?? 0}` },
            { name: lesson?.title ?? "-", url: '' },
            { name: 'Üniteler' ?? "-", url: '' },
        ]);
    }, [lesson])
    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        }
        else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        }
        else {
            return (
                <table className="card-body table">
                    <thead>
                        <tr>
                            <th scope="col">Ünite</th>
                            <th scope="col">Başlık</th>
                            <th scope="col">Durum</th>
                            <th scope="col">Son Güncelleme</th>
                            <th scope="col">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data === null || data.length === 0 ? <tr><td colSpan={7} className='text-center'>Henüz içerik eklenmemiş.</td></tr> : data.map((item, Index) => {
                                return (
                                    <Fragment key={"unit_" + Index}>

                                        <tr >
                                            <td>{item.unitNumber}</td>
                                            <th>{item.title}</th>
                                            <td>{status(item.status)}</td>
                                            <td>{item.updatedAt ? new Date(item.updatedAt.seconds * 1000).toISOString().slice(0, -5).replace("T", " ") : "-"}</td>
                                            <td>
                                                <div className="btn-group" role="group">
                                                    <Link to={item.id.toString()} className="btn btn-sm btn-primary" role="button">DÜZENLE</Link>


                                                    <div className="btn-group" role="group">
                                                        <button type="button" className="btn  btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            EKLE
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href={`/lesson-units/${item.id}/books/create`}>KİTAP</a></li>

                                                        </ul>
                                                    </div>


                                                    <Link target='_blank' to={`https://fma.sinavhane.net/lessons/units/books/${item.id}.pdf`} className="btn btn-sm btn-secondary" role="button">ÖNİZLE</Link>
                                                    <Link to={`${item.id.toString()}/contents`} className="btn btn-sm btn-primary" role="button">İÇERİKLER</Link>
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan={5}>
                                                {
                                                    item.booksList.map((book, index) => {
                                                        return <Link key={`book-${index}`} to={`/lesson-units/${item.id}/books/${book.id.toString()}`} className="btn btn-sm btn-primary" role="button">Kitap #{book.id}</Link>
                                                    })
                                                }
                                            </td>
                                        </tr>
                                    </Fragment>


                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-group">
                            <Link to="create" className="btn btn-sm btn-primary">+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default LessonUnits;