import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen.js';
import ErrorContent from '../../components/ErrorContent.js';
import NoContent from '../../components/NoContent.js';
import Breadcrumbs from '../../components/Breadcrumbs.js';
import { areaServiceListAreas } from '../../api/area.js';
import { lessonServiceGetLesson } from '../../api/lesson';
import { lessonBookServiceListLessonBooks } from '../../api/lesson_book';
import { lessonServiceListLessons } from '../../api/lesson.js';
import { academicYear, lessonContentType, status } from '../../api/types.js';

function LessonBooks() {
    const navigate = useNavigate();
    let { lessonId } = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lesson, setLesson] = useState(null);
    const [data, setData] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const loadScreen = async () => {
        if (lessonId === undefined)
            navigate(`/lessons`);

        try {
            await loadLesson();
            await loadLessonBooks();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadLesson = async () => {
        const response = await lessonServiceGetLesson(lessonId);
        const object = response.toObject();
        setLesson(object.lesson);
    }

    const loadLessonBooks = async () => {
        const response = await lessonBookServiceListLessonBooks({ lessonId: lessonId });
        const object = response.toObject();
        setData(object.lessonBooksList);
    }

    useEffect(() => {
        document.title = "Ders - Kitapları"

        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Ders Kitapları', url: '' },
        ]);
        loadScreen();
    }, []);

    useEffect(() => {
        document.title = `${lesson?.title ?? '-'} / Kitaplar`
        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Dersler', url: "/lessons" },
            { name: lesson?.area.title ?? "-", url: `/lessons?areaId=${lesson?.area.id ?? 0}` },
            { name: lesson?.title ?? "-", url: '' },
            { name: 'Kitaplar' ?? "-", url: '' },
        ]);
    }, [lesson])
    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        }
        else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        }
        else {
            return (
                <table className="card-body table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">İçerik Tipi</th>
                            <th scope="col">Hedef Url</th>
                            <th scope="col">Durum</th>
                            <th scope="col">Son Güncelleme</th>
                            <th scope="col">İşlemler</th>
                        </tr>

                    </thead>
                    <tbody>
                        {
                            data === null || data.length === 0 ? <tr><td colSpan={7} className='text-center'>Henüz içerik eklenmemiş.</td></tr> : data.map((item, Index) => {
                                return (
                                    <tr key={"area_" + Index}>
                                        <td>{item.id}</td>
                                        <td>{lessonContentType(item.lessonContentType)}</td>
                                        <th><a href={item.targetUrl} target='__blank'>Git.</a></th>
                                        <td>{status(item.status)}</td>
                                        <td>{item.updatedAt ? new Date(item.updatedAt.seconds * 1000).toISOString().slice(0, -5).replace("T", " ") : "-"}</td>
                                        <td>
                                            <div className="btn-group" role="group">
                                                <Link to={item.id.toString()} className="btn btn-sm btn-primary" role="button">DÜZENLE</Link>
                                                <Link target='_blank' to={`https://fma.sinavhane.net/lessons/books/${item.id}.pdf`} className="btn btn-sm btn-secondary" role="button">ÖNİZLE</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-group">
                            <Link to="create" className="btn btn-sm btn-primary">+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default LessonBooks;