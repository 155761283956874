import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { lessonContentType, status } from "../../api/types";
import { LessonBook, File, Area, Lesson } from "../../grpc/dash/v1/models_pb";
import { PutLessonBookRequest } from "../../grpc/dash/v1/lesson_book_pb";
import { lessonServiceGetLesson } from "../../api/lesson";
import { fileServiceFileUpload } from "../../api/file";
import { lessonBookServiceGetLessonBook, lessonBookServicePutLessonBook } from "../../api/lesson_book";
import FileToBase64 from "../../helpers/fileToBase64";
import { lessonBookService } from "../../api/services";

function LessonBookEdit() {
    let { lessonId } = useParams();
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [lesson, setLesson] = useState([]);
    const [request, setRequest] = useState(new PutLessonBookRequest().setLessonBook(new LessonBook()
        .setLesson(new Lesson().setId(parseInt(lessonId)))
        .setLessonContentType(0)
        .setStatus(0))
    );




    const loadScreen = async () => {
        if (lessonId === undefined)
            navigate(`/lessons`);
        try {
            // LOAD LESSON
            const responseLesson = await lessonServiceGetLesson(lessonId);
            const objectLesson = responseLesson.toObject().lesson;
            setLesson(objectLesson);
            console.log(objectLesson)

            const breadcrumbItems = [
                { name: 'Dash', url: '/' },
                { name: 'Dersler', url: '/lessons' },
                { name: objectLesson?.title ?? "-", url: '' },
                { name: "Kitaplar", url: `/lessons/${objectLesson?.id}/books` }
            ];

            if (id === undefined) {
                breadcrumbItems.push({ name: "Yeni", url: "" });
            } else {
                const result = await lessonBookServiceGetLessonBook(id);
                request.getLessonBook().setId(result.getLessonBook().getId());
                request.getLessonBook().setLessonContentType(result.getLessonBook().getLessonContentType());
                request.getLessonBook().setStatus(result.getLessonBook().getStatus());
                request.getLessonBook().setTargetUrl(result.getLessonBook().getTargetUrl());
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            await lessonBookService.putLessonBook(request);
            navigate(`/lessons/${lessonId}/books`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }

    const handleFileChange = async event => {
        const _file = event.target.files && event.target.files[0];
        if (!_file) {
            request.clearFile();
            return;
        }
        const object = await FileToBase64(_file);
        request.setFile(new File()
            .setName(object.name)
            .setType(object.type)
            .setContent(object.data)
        );
    };
    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />
        return <form className="card-body row g-3">
            <div className="col-md-12 py-1">
                <label htmlFor="form-lesson-content-type" className="form-label">İÇERİK TİPİ</label>
                <select id="form-lesson-content-type" className="form-select" defaultValue={request.getLessonBook().getLessonContentType()} onChange={e => request.getLessonBook().setLessonContentType(parseInt(e.target.value))}>
                    {
                        Object.values(lessonContentType()).map((value, index) => (
                            <option key={"lesson_content_type" + index} value={value}>{lessonContentType(value)}</option>
                        ))
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-status" className="form-label">DURUM</label>
                <select id="form-status" className="form-select" defaultValue={request.getLessonBook().getStatus()} onChange={e => request.getLessonBook().setStatus(parseInt(e.target.value))}>
                    {
                        Object.values(status()).map((value, index) => (
                            <option key={"status" + index} value={value}>{status(value)}</option>
                        ))
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-target-url" className="form-label">HEDEF URL</label>
                <input type="text" className="form-control" id="form-target-url" defaultValue={request.getLessonBook().getTargetUrl()} onChange={e => {
                    request.getLessonBook().setTargetUrl(e.target.value);
                }} />
            </div>
            <div className="col-md-6">
                <label htmlFor="form-content" className="form-label">Dosya ile Yükle</label>
                <input type="file" className="form-control" id="form-content" onChange={handleFileChange} />
            </div>
            <div className="col-md-6">
                <label htmlFor="form-content-target-url" className="form-label">Hedef url ile yükle</label>
                <br />
                <input className="form-check-input" type="checkbox" id="form-content-target-url" onChange={e => {
                    if (e.target.checked == false)
                        request.clearTargetUrl();
                    else
                        request.setTargetUrl(e.target.checked);
                }} />
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default LessonBookEdit;